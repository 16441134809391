import React from "react";
import { Form, Segment, Grid, Image } from "semantic-ui-react";
import Photographer from "./fields/Photographer";
import Location from "./fields/Location";
import Album from "./fields/Album";
import Tags from "./fields/Tags";
import Calendar from "./fields/Calendar";
import Registration from "./fields/Registration";
import Airline from "./fields/Airline";
import Manufacturer from "./fields/Manufacturer";
import Model from "./fields/Model";
import FlightNumber from "./fields/FlightNumber";
import Departure from "./fields/Departure";
import Arrival from "./fields/Arrival";
import Notes from "./fields/Notes";

export default function SearchFields() {
  return (
    <Grid stackable verticalAlign="middle">
      <Grid.Column>
        {/* In this case, onChange is a disregarded arrow function so we don't get errors (onChange is also called from globalEntry) */}
        <Form.Group widths="equal">
          <Photographer></Photographer>
          <Calendar></Calendar>
          <Location></Location>
        </Form.Group>
        <Form.Group widths="equal">
          <Album></Album>
          <Tags></Tags>
        </Form.Group>
        <Form.Group widths="equal">
          <Registration></Registration>
          <Airline></Airline>
          <Manufacturer></Manufacturer>
          <Model></Model>
        </Form.Group>
        <Form.Group widths="equal">
          <FlightNumber></FlightNumber>
          <Departure></Departure>
          <Arrival></Arrival>
        </Form.Group>
        <Form.Group widths="equal">
          <Notes></Notes>
        </Form.Group>
      </Grid.Column>
    </Grid>
  );
}
