import React from "react";
import { Dropdown, Form, FormDropdown } from "semantic-ui-react";

const options = [
  { value: "", text: "Select Photographer" },
  { value: "Filip Graniczny", text: "Filip Graniczny" },
  { value: "Adam Witusik", text: "Adam Witusik" },
  { value: "Albert Lua", text: "Albert Lua" },
];

function Photographer(props) {
  return (
    <FormDropdown
      placeholder="Select Photographer"
      label="Photographer"
      fluid
      search
      selection
      options={options}
    />
  );
}

export default Photographer;
