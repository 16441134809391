import React from "react";
import { Feed, Icon } from "semantic-ui-react";

const FeedList = () => (
  <Feed size="large" style={{ fontSize: "20px", lineHeight: "30px" }}>
    <Feed.Event style={feedItemStyle}>
      <Feed.Label image="https://react.semantic-ui.com/images/avatar/small/elliot.jpg" />
      <Feed.Content>
        <Feed.Date>4 days ago</Feed.Date>
        <Feed.Summary>
          <a>Albert Lua</a> added <a>4 new photos.</a>
        </Feed.Summary>

        <Feed.Extra images>
          <a>
            <img src="https://react.semantic-ui.com/images/wireframe/image.png" />
          </a>
          <a>
            <img src="https://react.semantic-ui.com/images/wireframe/image.png" />
          </a>
          <a>
            <img src="https://react.semantic-ui.com/images/wireframe/image.png" />
          </a>
          <a>
            <img src="https://react.semantic-ui.com/images/wireframe/image.png" />
          </a>
        </Feed.Extra>
      </Feed.Content>
    </Feed.Event>

    <Feed.Event style={feedItemStyle}>
      <Feed.Label image="https://react.semantic-ui.com/images/avatar/small/helen.jpg" />
      <Feed.Content>
        <Feed.Date>4 days ago</Feed.Date>
        <Feed.Summary>
          <a>Adam Witusik</a> added <a>a new airline.</a>
        </Feed.Summary>
      </Feed.Content>
    </Feed.Event>

    <Feed.Event style={feedItemStyle}>
      <Feed.Label image="https://react.semantic-ui.com/images/avatar/small/helen.jpg" />
      <Feed.Content>
        <Feed.Date>4 days ago</Feed.Date>
        <Feed.Summary>
          <a>Adam Witusik</a> added <a>2 new photos.</a>
        </Feed.Summary>

        <Feed.Extra images>
          <a>
            <img src="https://react.semantic-ui.com/images/wireframe/image.png" />
          </a>
          <a>
            <img src="https://react.semantic-ui.com/images/wireframe/image.png" />
          </a>
        </Feed.Extra>
      </Feed.Content>
    </Feed.Event>
  </Feed>
);

const feedItemStyle = {
  paddingBottom: "20px",
};

export default FeedList;
