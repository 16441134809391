import React, { Component } from "react";
import { Menu, Input, Dropdown, Icon } from "semantic-ui-react";

export default class StackableMenu extends Component {
  state = {};

  handleItemClick = (e, { name }) => this.setState({ activeItem: name });

  render() {
    const { activeItem } = this.state;

    return (
      <Menu stackable size="massive">
        <Menu.Item>
          <img src="/logo.png" style={{ width: "10vw" }} />
        </Menu.Item>

        <Menu.Item
          name="home"
          active={activeItem === "home"}
          onClick={this.handleItemClick}
        >
          <Icon name="home" />
          Home
        </Menu.Item>
        <Menu.Item
          name="search"
          active={activeItem === "search"}
          onClick={this.handleItemClick}
        >
          <Icon name="search" />
          Search
        </Menu.Item>

        <Menu.Item
          name="upload"
          active={activeItem === "upload"}
          onClick={this.handleItemClick}
        >
          <Icon name="upload" />
          Upload
        </Menu.Item>
        <Menu.Menu position="right">
          <Menu.Item>
            <Input
              action={{ type: "submit", content: "Go" }}
              placeholder="Search for..."
            />
          </Menu.Item>
          <Dropdown item text="My Account">
            <Dropdown.Menu>
              <Dropdown.Item>Settings</Dropdown.Item>
              <Dropdown.Item>Logout</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Menu.Menu>
      </Menu>
    );
  }
}
