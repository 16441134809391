import { Grid, Container } from "semantic-ui-react";
import LoginCard from "./LoginCard";
import Background from "../Background";

const Login = () => {
  return (
    <div>
      <Background url="http://albums.fandagear.com/random/photo.php"></Background>
      <Container>
        <Grid style={{ height: "100vh" }} verticalAlign="middle">
          <Grid.Column>
            <LoginCard />
          </Grid.Column>
        </Grid>
      </Container>
    </div>
  );
};

export default Login;
