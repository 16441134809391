import React from "react";
import { Button } from "semantic-ui-react";

export default function UploadButton() {
  return (
    <Button fluid size="massive">
      Upload
    </Button>
  );
}
