import React from "react";
import { FormDropdown } from "semantic-ui-react";

export default function SortAttributeDropdown() {
  const options = [
    { value: "", text: "Airline" },
    { value: "", text: "Manufacturer" },
    { value: "", text: "Model" },
    { value: "", text: "Location" },
    { value: "", text: "Photographer" },
  ];
  return (
    <FormDropdown
      placeholder="Sort by Attribute..."
      selection
      options={options}
    />
  );
}
