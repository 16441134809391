import React from "react";

export default function Background(props) {
  const bgStyle = {
    minHeight: "110vh",
    display: "flex",
    background: `-webkit-linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${props.url}) no-repeat center center`,
    backgroundSize: "cover",
    position: "fixed",
    left: "0",
    right: "0",
  };
  return <div style={bgStyle}></div>;
}
