import React, { useState } from "react";
import { Container, SegmentGroup, Image } from "semantic-ui-react";

import Navbar from "../menu/Navbar";
import DragDrop from "./DragDrop";
import GlobalEntry from "./GlobalEntry";
import ImageSection from "./ImageSection";
import UploadButton from "./UploadButton";

export default function Upload() {
  const [fileNames, setFileNames] = useState([]);
  const updateFiles = (acceptedFiles) => {
    setFileNames(fileNames.concat(acceptedFiles.map((file) => file)));
  };
  const [globalVal, setGlobalVal] = useState({
    photographer: "",
    date: "",
    airport: "",
    album: "",
    tags: [],
  });

  var id = -1;

  return (
    <div>
      <Navbar></Navbar>
      <DragDrop onDrop={updateFiles}></DragDrop>
      <br></br>
      <Container>
        <GlobalEntry
          onChange={setGlobalVal}
          globalVal={globalVal}
        ></GlobalEntry>
        <SegmentGroup>
          {fileNames.map((fileName) => {
            id = id + 1;
            return (
              <ImageSection
                file={fileName}
                globalVal={globalVal}
                setGlobalVal={setGlobalVal}
                fileNames={fileNames}
                setFileNames={setFileNames}
                id={id}
              ></ImageSection>
            );
          })}
        </SegmentGroup>
        <UploadButton></UploadButton>
        <br></br>
      </Container>
    </div>
  );
}
