import React, { Component } from "react";
import { FormDropdown } from "semantic-ui-react";

const options = [
  { value: "", text: "Select Model" },
  { value: "737-800", text: "737-800" },
  { value: "787-8", text: "787-8" },
  { value: "777-300ER", text: "777-300ER" },
];

class Model extends Component {
  state = { options };

  handleAddition = (e, { value }) => {
    this.setState((prevState) => ({
      options: [{ text: value, value }, ...prevState.options],
    }));
  };

  handleChange = (e, { value }) => this.setState({ currentValue: value });

  render() {
    const { currentValue } = this.state;

    return (
      <FormDropdown
        options={this.state.options}
        label="Model"
        placeholder="Select Model"
        search
        selection
        fluid
        allowAdditions
        value={currentValue}
        onAddItem={this.handleAddition}
        onChange={this.handleChange}
      />
    );
  }
}

export default Model;
