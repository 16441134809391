import React from "react";
import Navbar from "../menu/Navbar";
import Splash from "../home/Splash";
import { Grid } from "semantic-ui-react";
import Feed from "../home/Feed";
import QuickStats from "../home/QuickStats";

export default function Home() {
  return (
    <div>
      <Navbar></Navbar>
      <Splash></Splash>
      <Grid columns={2} stackable style={{ maxWidth: "100vw" }}>
        <Grid.Column>
          <div style={feedStyle}>
            <Feed></Feed>
          </div>
        </Grid.Column>
        <Grid.Column>
          <div style={feedStyle}>
            <QuickStats></QuickStats>
          </div>
        </Grid.Column>
      </Grid>
    </div>
  );
}

const feedStyle = {
  margin: "auto",
  width: "90%",
  padding: "10px",
  paddingTop: "25px",
};
