import React from "react";
import { Table, Label, Header } from "semantic-ui-react";

export default function ImageDetails() {
  return (
    <Table
      basic="very"
      celled
      selectable
      structured
      stackable
      textAlign="center"
      verticalAlign="middle"
      columns="2"
    >
      <Table.Body>
        <Table.Row>
          <Table.Cell>
            <b>Photo ID</b>
          </Table.Cell>
          <Table.Cell>
            <Label color="black">557376</Label>
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>
            <b>Date Taken</b>
          </Table.Cell>
          <Table.Cell>06/14/2020</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>
            <b>Photographer</b>
          </Table.Cell>
          <Table.Cell>Albert Lua</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>
            <b>Registration</b>
          </Table.Cell>
          <Table.Cell>SP-LRG</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>
            <b>Airline</b>
          </Table.Cell>
          <Table.Cell>LOT Polish Airlines</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>
            <b>Aircraft</b>
          </Table.Cell>
          <Table.Cell>Boeing 787-8</Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  );
}
