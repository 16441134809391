import React from "react";
import { Button, Form } from "semantic-ui-react";

export default function SearchButton() {
  return (
    <Form.Button fluid type="submit" size="massive" primary>
      Search
    </Form.Button>
  );
}
