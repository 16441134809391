import React from "react";
import { FormDropdown } from "semantic-ui-react";

export default function SortDateDropdown() {
  const options = [
    { value: "", text: "Capture Date - Newest First" },
    { value: "", text: "Capture Date - Oldest First" },
    { value: "", text: "Upload Date - Newest First" },
    { value: "", text: "Upload Date - Oldest First" },
  ];
  return (
    <FormDropdown placeholder="Sort by Date..." selection options={options} />
  );
}
