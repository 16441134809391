import { Container, Grid, Segment, Form, Button } from "semantic-ui-react";
import Navbar from "../menu/Navbar";
import Photographer from "./fields/Photographer";
import Location from "./fields/Location";
import Album from "./fields/Album";
import Tags from "./fields/Tags";
import Calendar from "./fields/Calendar";
import Registration from "./fields/Registration";
import Airline from "./fields/Airline";
import Manufacturer from "./fields/Manufacturer";
import Model from "./fields/Model";
import FlightNumber from "./fields/FlightNumber";
import Departure from "./fields/Departure";
import Arrival from "./fields/Arrival";
import Notes from "./fields/Notes";
import SearchFields from "./SearchFields";
import SearchButton from "./SearchButton";
import SearchPreRefine from "./SearchPreRefine";
import React, { useState } from "react";

export default function SearchBlock(props) {
  const handleSubmit = () => {
    props.show[1](!props.show[0]);
  };
  return (
    <Segment>
      <Form className="attached fluid" onSubmit={handleSubmit}>
        <SearchFields></SearchFields>
        <br></br>
        <SearchButton></SearchButton>
      </Form>
    </Segment>
  );
}
