import React from "react";
import { Button, Form, Icon, Message } from "semantic-ui-react";
import Photographer from "./fields/Photographer";
import Location from "./fields/Location";
import Album from "./fields/Album";
import Tags from "./fields/Tags";
import Calendar from "./fields/Calendar";

function GlobalEntry(props) {
  return (
    <div>
      <Message
        info
        attached
        header="Global Fields"
        content="The information in these fields will apply to all uploaded photos"
      />
      <Form className="attached fluid segment">
        <Form.Group widths="equal">
          <Photographer
            onChange={props.onChange}
            globalVal={props.globalVal}
          ></Photographer>
          <Calendar
            onChange={props.onChange}
            globalVal={props.globalVal}
          ></Calendar>
          <Location
            onChange={props.onChange}
            globalVal={props.globalVal}
          ></Location>
        </Form.Group>
        <Form.Group widths="equal">
          <Album onChange={props.onChange} globalVal={props.globalVal}></Album>
          <Tags onChange={props.onChange} globalVal={props.globalVal}></Tags>
        </Form.Group>
      </Form>
    </div>
  );
}

export default GlobalEntry;
