import React, { Component } from "react";
import { FormDropdown } from "semantic-ui-react";

const options = [
  { value: "", text: "Select Manu." },
  { value: "Airbus", text: "Airbus" },
  { value: "Boeing", text: "Boeing" },
  { value: "Mitsubishi", text: "Mitsubishi" },
];

class Manufacturer extends Component {
  state = { options };

  handleAddition = (e, { value }) => {
    this.setState((prevState) => ({
      options: [{ text: value, value }, ...prevState.options],
    }));
  };

  handleChange = (e, { value }) => this.setState({ currentValue: value });

  render() {
    const { currentValue } = this.state;

    return (
      <FormDropdown
        options={this.state.options}
        label="Manufacturer"
        placeholder="Select Manu."
        search
        selection
        fluid
        allowAdditions
        value={currentValue}
        onAddItem={this.handleAddition}
        onChange={this.handleChange}
      />
    );
  }
}

export default Manufacturer;
