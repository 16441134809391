import React from "react";
import { Button } from "semantic-ui-react";

export default function PageNavigation() {
  return (
    <Button.Group basic>
      <Button>1</Button>
      <Button>2</Button>
      <Button>...</Button>
      <Button>9</Button>
    </Button.Group>
  );
}
