import React from "react";
import {
  Button,
  Card,
  Header,
  Grid,
  Form,
  Container,
  Image,
  Item,
} from "semantic-ui-react";

export default function LoginCard() {
  return (
    <Card centered style={shadow}>
      <Card.Content>
        <Grid.Column textAlign="center">
          <Image
            src="/logo.png"
            size="small"
            style={{ marginBottom: "10px", marginTop: "10px" }}
          ></Image>
        </Grid.Column>
        <Form>
          <Form.Input label="Username"></Form.Input>
          <Form.Input label="Password"></Form.Input>
          <Button color="blue" circular fluid>
            Log In
          </Button>
          <Button style={{ marginTop: 10 }} circular fluid>
            Create Account
          </Button>
        </Form>
      </Card.Content>
    </Card>
  );
}

const shadow = {
  boxShadow: "rgb(38, 57, 77) 0px 30px 40px -10px",
};
