import React, { Component } from "react";
import { FormDropdown } from "semantic-ui-react";

const options = [
  { text: "AirVenture 2020", value: "AirVenture 2020" },
  { text: "Golden Hour", value: "Golden Hour" },
  { text: "Beacon Shot", value: "Beacon Shot" },
];

class Tags extends Component {
  state = { options };

  handleAddition = (e, { value }) => {
    this.setState((prevState) => ({
      options: [{ text: value, value }, ...prevState.options],
    }));
  };

  handleChange = (e, { value }) => {
    this.setState({ currentValues: value });
  };

  render() {
    const { currentValues } = this.state;
    return (
      <FormDropdown
        options={this.state.options}
        placeholder="Choose Tags"
        label="Tags"
        search
        selection
        fluid
        multiple
        allowAdditions
        value={currentValues}
        onAddItem={this.handleAddition}
        onChange={(e, { value }) =>
          this.props.onChange({ ...this.props.globalVal, tags: value })
        }
        disabled={
          this.props.globalVal.tags.length > 0 && this.props.allowDisable
        }

      />
    );
  }
}

export default Tags;
