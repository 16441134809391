import React, { Component } from "react";
import { FormDropdown } from "semantic-ui-react";

const options = [
  { value: "", text: "Select Airport" },
  { text: "ORD / KORD", value: "ORD / KORD", flag: "us" },
  { text: "KRK / EPKK", value: "KRK / EPKK", flag: "pl" },
];

class Location extends Component {
  state = { options };

  handleAddition = (e, { value }) => {
    this.setState((prevState) => ({
      options: [{ text: value, value }, ...prevState.options],
    }));
  };

  handleChange = (e, { value }) => this.setState({ currentValue: value });

  render() {
    const { currentValue } = this.state;

    return (
      <FormDropdown
        options={this.state.options}
        label="Airport"
        placeholder="Select Airport"
        search
        selection
        fluid
        allowAdditions
        value={currentValue}
        onAddItem={this.handleAddition}
        onChange={this.handleChange}
      />
    );
  }
}

export default Location;
