import React from "react";
import {
  Button,
  Divider,
  Grid,
  Header,
  Icon,
  Segment,
} from "semantic-ui-react";

const Splash = () => (
  <Segment placeholder>
    <Grid columns={2} stackable textAlign="center">
      <Divider vertical>Or</Divider>

      <Grid.Row verticalAlign="middle">
        <Grid.Column>
          <Header icon>
            <Icon name="search" />
            Search Database
          </Header>
          <Button primary>Search</Button>
        </Grid.Column>
        <Grid.Column>
          <Header icon>
            <Icon name="upload" />
            Add Photos
          </Header>
          <Button primary>Upload</Button>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Segment>
);

export default Splash;
