import React from "react";
import { Form } from "semantic-ui-react";

export default function Calendar(props) {
  return (
    <Form.Input
      fluid
      icon="calendar"
      label="Date Taken"
      placeholder="MM/DD/YYYY"
      type="text"
    />
  );
}
