import React from "react";
import { Form } from "semantic-ui-react";

export default function FlightNumber() {
  return (
    <Form.Input
      fluid
      label="Flight Number"
      placeholder="Enter Flight Number"
      type="text"
    />
  );
}
