import React from "react";
import { Icon, Image, Statistic } from "semantic-ui-react";
import { Grid } from "semantic-ui-react";
import { ResponsiveBar } from "@nivo/bar";
import data from "../home/chartData/data";
import data2 from "../home/chartData/data2";
import data3 from "../home/chartData/data3";
import data4 from "../home/chartData/data4";


const QuickStats = () => (
  <div>
    <Grid columns={2} stackable style={{ maxWidth: "100vw" }}>
      <Grid.Column>
        <h2 align="center"> My Statistics</h2>
        <Statistic.Group>
          <Statistic style={statStyle} size="huge">
            <Statistic.Value>712</Statistic.Value>
            <Statistic.Label>Photos</Statistic.Label>
          </Statistic>

          <Statistic style={statStyle} size="huge">
            <Statistic.Value>17</Statistic.Value>
            <Statistic.Label>Airports</Statistic.Label>
          </Statistic>

          <Statistic style={statStyle} size="huge">
            <Statistic.Value>111</Statistic.Value>
            <Statistic.Label>Airlines</Statistic.Label>
          </Statistic>

          <Statistic style={statStyle} size="huge">
            <Statistic.Value>59</Statistic.Value>
            <Statistic.Label>Manufacturers</Statistic.Label>
          </Statistic>
        </Statistic.Group>
      </Grid.Column>
      <Grid.Column>
        <h2 align="center"> Global Statistics</h2>
        <Statistic.Group>
          <Statistic style={statStyle} size="huge">
            <Statistic.Value>2,998</Statistic.Value>
            <Statistic.Label>Photos</Statistic.Label>
          </Statistic>

          <Statistic style={statStyle} size="huge">
            <Statistic.Value>29</Statistic.Value>
            <Statistic.Label>Airports</Statistic.Label>
          </Statistic>

          <Statistic style={statStyle} size="huge">
            <Statistic.Value>174</Statistic.Value>
            <Statistic.Label>Airlines</Statistic.Label>
          </Statistic>

          <Statistic style={statStyle} size="huge">
            <Statistic.Value>80</Statistic.Value>
            <Statistic.Label>Manufacturers</Statistic.Label>
          </Statistic>
        </Statistic.Group>
      </Grid.Column>
    </Grid>
  </div>
);

const statStyle = {
  width: "100%",
  paddingTop: "15px",
  paddingBottom: "15px",
  size: "large",
};

const MyResponsiveBar = () => (
  <div style={{ height: "85px" }}>
  <h2 align="center"> Pictures <Icon name = "photo" /></h2>
  <ResponsiveBar
      data={data}
      keys={[ 'Adam Witusik','Total' ]}
      indexBy="Pictures"
      margin={{ top: 0, right: 60, bottom: 0, left: 60 }}
      padding={0.0}
      groupMode="grouped"
      layout="horizontal"
      valueScale={{ type: 'linear' }}
      indexScale={{ type: 'band', round: true }}
      colors={{ scheme: 'paired' }}
      axisTop={null}
      axisRight={null}
      axisBottom={null}
      axisLeft={null}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor="#ffffff"
      label={d => `${d.id}: ${d.value}`}
      legends={[]}
      animate={true}
      motionStiffness={90}
      motionDamping={15}
  />
  <h2 align="center"> Locations <Icon name = "map" /></h2>
  <ResponsiveBar
      data={data2}
      keys={[ 'Adam Witusik','Total' ]}
      indexBy="Locations"
      margin={{ top: 0, right: 60, bottom: 0, left: 60 }}
      padding={0.0}
      groupMode="grouped"
      layout="horizontal"
      valueScale={{ type: 'linear' }}
      indexScale={{ type: 'band', round: true }}
      colors={{ scheme: 'paired' }}
      axisTop={null}
      axisRight={null}
      axisBottom={null}
      axisLeft={null}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor="#ffffff"
      label={d => `${d.id}: ${d.value}`}
      legends={[]}
      animate={true}
      motionStiffness={90}
      motionDamping={15}
  />
  <h2 align="center"> Airlines <Icon name = "plane" /></h2>
  <ResponsiveBar
      data={data3}
      keys={[ 'Adam Witusik','Total' ]}
      indexBy="Airlines"
      margin={{ top: 0, right: 60, bottom: 0, left: 60 }}
      padding={0.0}
      groupMode="grouped"
      layout="horizontal"
      valueScale={{ type: 'linear' }}
      indexScale={{ type: 'band', round: true }}
      colors={{ scheme: 'paired' }}
      axisTop={null}
      axisRight={null}
      axisBottom={null}
      axisLeft={null}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor="#ffffff"
      label={d => `${d.id}: ${d.value}`}
      legends={[]}
      animate={true}
      motionStiffness={90}
      motionDamping={15}
  />
  <h2 align="center"> Manufacturers <Icon name = "factory" /></h2>
  <ResponsiveBar
      data={data4}
      keys={[ 'Adam Witusik','Total' ]}
      indexBy="Manufacturers"
      margin={{ top: 0, right: 60, bottom: 0, left: 60 }}
      padding={0.0}
      groupMode="grouped"
      layout="horizontal"
      valueScale={{ type: 'linear' }}
      indexScale={{ type: 'band', round: true }}
      colors={{ scheme: 'paired' }}
      axisTop={null}
      axisRight={null}
      axisBottom={null}
      axisLeft={null}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor="#ffffff"
      label={d => `${d.id}: ${d.value}`}
      legends={[]}
      animate={true}
      motionStiffness={90}
      motionDamping={15}
  />
  </div>

)

export default MyResponsiveBar;
