import { Container, Grid, Segment, Form, Button } from "semantic-ui-react";
import Navbar from "../menu/Navbar";
import Photographer from "./fields/Photographer";
import Location from "./fields/Location";
import Album from "./fields/Album";
import Tags from "./fields/Tags";
import Calendar from "./fields/Calendar";
import Registration from "./fields/Registration";
import Airline from "./fields/Airline";
import Manufacturer from "./fields/Manufacturer";
import Model from "./fields/Model";
import FlightNumber from "./fields/FlightNumber";
import Departure from "./fields/Departure";
import Arrival from "./fields/Arrival";
import Notes from "./fields/Notes";
import SearchFields from "./SearchFields";
import SearchButton from "./SearchButton";
import SearchPreRefine from "./SearchPreRefine";
import React, { useState } from "react";
import SearchBlock from "./SearchBlock";
import Result from "./Result";

export default function Search() {
  const [searchShowing, setSearchShowing] = useState(true);
  return (
    <div>
      <Navbar></Navbar>
      <Container>
        <Grid stackable verticalAlign="middle">
          <Grid.Column>
            {searchShowing && (
              <SearchBlock
                show={[searchShowing, setSearchShowing]}
              ></SearchBlock>
            )}
            {!searchShowing && (
              <SearchPreRefine
                show={[searchShowing, setSearchShowing]}
              ></SearchPreRefine>
            )}
            {/* Results here, based on API call */}
            <Result></Result>
            {!searchShowing && (
              <SearchPreRefine
                show={[searchShowing, setSearchShowing]}
              ></SearchPreRefine>
            )}
          </Grid.Column>
        </Grid>
      </Container>
    </div>
    //
  );
}
