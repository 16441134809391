import React from "react";
import { Button, Form } from "semantic-ui-react";

export default function ViewPhotoButton() {
  return (
    <Form.Button fluid type="submit" secondary>
      View Photo
    </Form.Button>
  );
}
