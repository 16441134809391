import React from "react";
import { Button } from "semantic-ui-react";

export default function RemoveButton(props) {
  function removeImage() {
    var array = [...props.fileNames];
    var index = props.id;
    if (index !== -1) {
      array.splice(index, 1);
      props.setFileNames(array);
    }
  }
  return (
    <Button color="red" size="massive" onClick={removeImage} fluid>
      Remove
    </Button>
  );
}
