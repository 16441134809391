import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import Login from "../components/login/Login";
import Search from "../components/search/Search";
import Home from "../components/home/Home";
import Header from "../components/header/Header";
import Upload from "../components/upload/Upload";
import Results from "../components/results/Results";


const Routes = () => {
  return (
    <Router>
      <Header />
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/home" component={Home} />
        <Route exact path="/search" component={Search} />
        <Route exact path="/upload" component={Upload} />
        <Route exact path="/results" component={Results} />
      </Switch>
    </Router>
  );
};

export default Routes;
