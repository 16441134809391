import React from "react";
import { Segment, Grid, Button, Form } from "semantic-ui-react";
import PageNavigation from "./PageNavigation";
import SortAttributeDropdown from "./SortAttributeDropdown";
import SortDateDropdown from "./SortDateDropdown";

export default function SearchPreRefine(props) {
  const handleClick = () => {
    props.show[1](!props.show[0]);
    window.scrollTo(0, 0);
  };
  return (
    <Segment>
      <Grid columns={4} stackable verticalAlign="middle">
        <Grid.Column>
          <Button onClick={handleClick}>Refine Search</Button>
        </Grid.Column>
        <Grid.Column>
          <SortDateDropdown></SortDateDropdown>
        </Grid.Column>
        <Grid.Column>
          <SortAttributeDropdown></SortAttributeDropdown>
        </Grid.Column>
        <Grid.Column>
          <PageNavigation></PageNavigation>
        </Grid.Column>
      </Grid>
    </Segment>
  );
}
