import React from "react";
import { Segment, Grid, Image, Form, Button } from "semantic-ui-react";
import Photographer from "./fields/Photographer";
import Location from "./fields/Location";
import Album from "./fields/Album";
import Tags from "./fields/Tags";
import Calendar from "./fields/Calendar";
import Registration from "./fields/Registration";
import Airline from "./fields/Airline";
import Manufacturer from "./fields/Manufacturer";
import Model from "./fields/Model";
import FlightNumber from "./fields/FlightNumber";
import Departure from "./fields/Departure";
import Arrival from "./fields/Arrival";
import Notes from "./fields/Notes";
import RemoveButton from "./RemoveButton";

export default function ImageSection(props) {
  return (
    <Segment>
      <Grid columns={2} stackable verticalAlign="middle">
        <Grid.Column style={{ width: "40%" }}>
          <Image src={URL.createObjectURL(props.file)} fluid rounded></Image>
          <br></br>
          <RemoveButton
            fileNames={props.fileNames}
            setFileNames={props.setFileNames}
            id={props.id}
          ></RemoveButton>
        </Grid.Column>
        <Grid.Column style={{ width: "60%" }}>
          <Form className="attached fluid segment">
            {/* In this case, onChange is a disregarded arrow function so we don't get errors (onChange is also called from globalEntry) */}
            <Form.Group widths="equal">
              <Photographer
                onChange={() => {}}
                globalVal={props.globalVal}
                allowDisable
              ></Photographer>
              <Calendar
                onChange={() => {}}
                globalVal={props.globalVal}
                allowDisable
              ></Calendar>
              <Location
                onChange={() => {}}
                globalVal={props.globalVal}
                allowDisable
              ></Location>
            </Form.Group>
            <Form.Group widths="equal">
              <Album
                onChange={() => {}}
                globalVal={props.globalVal}
                allowDisable
              ></Album>
              <Tags
                onChange={() => {}}
                globalVal={props.globalVal}
                allowDisable
              ></Tags>
            </Form.Group>
            <Form.Group widths="equal">
              <Registration
                onChange={() => {}}
                globalVal={props.globalVal}
                allowDisable
              ></Registration>
              <Airline
                onChange={() => {}}
                globalVal={props.globalVal}
                allowDisable
              ></Airline>
              <Manufacturer
                onChange={() => {}}
                globalVal={props.globalVal}
                allowDisable
              ></Manufacturer>
              <Model
                onChange={() => {}}
                globalVal={props.globalVal}
                allowDisable
              ></Model>
            </Form.Group>
            <Form.Group widths="equal">
              <FlightNumber
                onChange={() => {}}
                globalVal={props.globalVal}
                allowDisable
              ></FlightNumber>
              <Departure
                onChange={() => {}}
                globalVal={props.globalVal}
                allowDisable
              ></Departure>
              <Arrival
                onChange={() => {}}
                globalVal={props.globalVal}
                allowDisable
              ></Arrival>
            </Form.Group>
            <Form.Group widths="equal">
              <Notes
                onChange={() => {}}
                globalVal={props.globalVal}
                allowDisable
              ></Notes>
            </Form.Group>
          </Form>
        </Grid.Column>
      </Grid>
    </Segment>
  );
}
