import React from "react";
import { Segment, Table, Image } from "semantic-ui-react";
import ImageDetails from "./ImageDetails";
import ViewPhotoButton from "./ViewPhotoButton";

export default function Result() {
  return (
    <Segment>
      <Table basic="very" celled>
        <Table.Body>
          <Table.Row>
            <Table.Cell style={{ width: "40%" }}>
              <Image
                src="https://albums.fandagear.com/random/photo.php"
                fluid
                rounded
              ></Image>
            </Table.Cell>
            <Table.Cell style={{ width: "60%" }}>
              <ImageDetails></ImageDetails>
              <ViewPhotoButton></ViewPhotoButton>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </Segment>
  );
}
