import React, { Component } from "react";
import { FormDropdown } from "semantic-ui-react";

const options = [
  { value: "", text: "Select Album" },
  {
    text: "ORD Spotting 12/23/2020",
    value: "ORD Spotting 12/23/2020",
  },
  {
    text: "KRK Spotting 11/21/2020",
    value: "KRK Spotting 11/21/2020",
  },
];

class Location extends Component {
  state = { options };

  handleAddition = (e, { value }) => {
    this.setState((prevState) => ({
      options: [{ text: value, value }, ...prevState.options],
    }));
  };

  handleChange = (e, { value }) => this.setState({ currentValue: value });

  render() {
    const { currentValue } = this.state;

    return (
      <FormDropdown
        options={this.state.options}
        label="Album"
        placeholder="Select an Album"
        search
        selection
        fluid
        allowAdditions
        value={currentValue}
        onAddItem={this.handleAddition}
        onChange={this.handleChange}
      />
    );
  }
}

export default Location;
