import React from "react";
import { Form } from "semantic-ui-react";

export default function Registration() {
  return (
    <Form.Input
      fluid
      label="Registration"
      placeholder="Enter Registration"
      type="text"
    />
  );
}
