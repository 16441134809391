import React, { useState } from "react";
import { Button, Header, Icon, Segment, Message } from "semantic-ui-react";

import Dropzone from "react-dropzone";

export default function DragDrop(props) {
  var bgColor = "";
  var message = "";
  const maxSize = 6291456;
  return (
    <div className="DragDrop">
      <Dropzone
        onDrop={props.onDrop}
        accept="image/*"
        minSize={1024}
        maxSize={maxSize}
      >
        {({
          getRootProps,
          getInputProps,
          isDragActive,
          isDragAccept,
          isDragReject,
          rejectedFiles,
        }) => {
          if (isDragReject) {
            bgColor = "";
            message = "Wrong file type or file size too big.";
          } else {
            bgColor = "";
            message = "";
          }
          return (
            <div {...getRootProps({ className: "dropzone" })}>
              <input {...getInputProps()} />
              <Segment placeholder style={{ backgroundColor: bgColor }}>
                <Header icon>
                  <Icon name="cloud upload" />
                  Drag and drop your photos here.
                </Header>
                <Button primary>Select photos</Button>
                {message !== "" ? typeError : ""}
              </Segment>
            </div>
          );
        }}
      </Dropzone>
    </div>
  );
}

const typeError = (
  <Message negative>
    <Message.Header>Incorrect File Type</Message.Header>
    <p>
      Please ensure you are uploading an image file and try again. Compatible
      file types include: PNG and JPEG
    </p>
  </Message>
);

const sizeError = (
  <Message negative>
    <Message.Header>File Size Too Large</Message.Header>
    <p>Please ensure all files are less than 6 MB in size.</p>
  </Message>
);
